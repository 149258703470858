<template>
  <div>
    <header1Component
      :circleActive="0"
      :detailActive="0"
      :loopCircleSmall="3"
      :circleSmall="0"
      titleHeader="หัวข้อ : 1.ข้อมูลขอรับการส่งเสริมและสนับสนุนเงินจากกองทุน"
    />
    <div class="container">
      <div class="card1">
        <div class="title">
          แบบคำขอรับทุนส่งเสริม สนับสนุน
          หรือให้ความช่วยเหลือจากกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคมตาม
          พระราชบัญญัติ การพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม พ.ศ. 2560
        </div>

        <div style="margin-bottom: 36px">
          <checkboxComponent
            class="mb-2"
            name="มาตรา 26 (1) ส่งเสริม สนับสนุน หรือให้ความช่วยเหลือหน่วยงานของรัฐและเอกชนหรือบุคคลทั่วไป ในการดำเนินการพัฒนาิจิทัลเพื่อเศรษฐกิจและสังคม ทั้งนี้ การส่งเสริม สนับสนุนหรือให้ความช่วยเหลือดังกล่าวต้องมีวัตถุประสงค์เพื่อประโยชน์ต่อการให้บริการสาธาารณะและไม่เป็นการแสวงหากำไรโดยไม่
เป็นการทำลายการแข่งขันอันพึงมีตามปกติวิสัยของกิจการภาคเอกชน"
            checkboxId="checkbox1_26"
            @click="checkboxCondition26('checkbox1_26')"
          />

          <checkboxComponent
            name="มาตรา 26 (2)
        ให้ทุนอุดหนุนการวิจัยและพัฒนาแก่หน่วยงานของรัฐและเอกชนหรือบุคคลทั่วไป
        ในเรื่องที่เกี่ยวกับกรพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม"
            checkboxId="checkbox2_26"
            @click="checkboxCondition26('checkbox2_26')"
          />
        </div>
      </div>

      <div class="card2">
        <div class="title conditionCard2">สถานะผู้ขอทุน</div>

        <div class="flex flex-row conditionCard2">
          <checkboxComponent
            name="บุคคลทั่วไป"
            checkboxId="checkbox1"
            @click="checkboxCondition('checkbox1')"
          />

          <checkboxComponent
            name="หน่วยงานเอกชน"
            checkboxId="checkbox2"
            @click="checkboxCondition('checkbox2')"
          />

          <checkboxComponent
            name="หน่วยงานของราชการ"
            checkboxId="checkbox3"
            @click="checkboxCondition('checkbox3')"
          />
        </div>

        <hr />

        <div
          class="flex flex-row"
          style="margin-bottom: 10px"
          v-for="(item, index) in profiles"
          :key="index"
        >
          <div class="contentActive" align="right">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
        </div>

        <hr />

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ชื่อผู้รับมอบอำนาจ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ชื่อผู้รับมอบอำนาจ"
              v-model="nameOfAttorney"
            />
          </div>
        </div>

        <hr />

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ชื่อโครงการภาษาไทย <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ชื่อโครงการภาษาไทย"
              v-model="projectNameTh"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ชื่อโครงการภาษาอังกฤษ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ชื่อโครงการภาษาอังกฤษ"
              v-model="projectNameEn"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 72px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ระยะเวลาโครงการ (วัน) <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ระยะเวลาโครงการ (วัน)"
              v-model="projectDurationDay"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ชื่อสถานที่ติดต่อ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ชื่อสถานที่ติดต่อ"
              v-model="addressContact1.placeNameContact"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div class="contentActive flex justify-end" align="right">
            ที่อยู่ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <textarea
              placeholder="ที่อยู่"
              v-model="addressContact1.addressName"
            ></textarea>
          </div>
        </div>

        <div class="flex flex-wrap" style="padding-left: 62px">
          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                รหัสไปรษณีย์ <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  v-model="addressContact1.zipCode"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                จังหวัด <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="จังหวัด"
                  v-model="addressContact1.province"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                อำเภอ/เขต <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="อำเภอ/เขต"
                  v-model="addressContact1.district"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                ตำบล/แขวง <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="ตำบล/แขวง"
                  v-model="addressContact1.subDistrict"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            อีเมล <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="อีเมล"
              v-model="addressContact1.email"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            เว็บไซต์ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="เว็บไซต์"
              v-model="addressContact1.website"
            />
          </div>
        </div>

        <hr />

        <checkboxComponent
          class="conditionCard2"
          style="margin-bottom: 15px"
          name="ที่อยู่เดียวกับสถานที่ติดต่อ"
          checkboxId="checkboxAddress"
          @click="checkboxConditionAddress()"
        />

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ชื่อสถานที่ติดต่อ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ชื่อสถานที่ติดต่อ"
              v-model="addressContact2.placeNameContact"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div class="contentActive flex justify-end" align="right">
            ที่อยู่ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <textarea
              placeholder="ที่อยู่"
              v-model="addressContact2.addressName"
            ></textarea>
          </div>
        </div>

        <div class="flex flex-wrap" style="padding-left: 62px">
          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                รหัสไปรษณีย์ <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  v-model="addressContact2.zipCode"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                จังหวัด <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="จังหวัด"
                  v-model="addressContact2.province"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                อำเภอ/เขต <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="อำเภอ/เขต"
                  v-model="addressContact2.district"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                ตำบล/แขวง <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="ตำบล/แขวง"
                  v-model="addressContact2.subDistrict"
                />
              </div>
            </div>
          </div>
        </div>

        <div align="right" style="margin-right: 72px; margin-top: 81px">
          <button @click="createRequest()">ถัดไป</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useRequest from "../../hooks/useRequest";
import header1Component from "@/components/capital/header1Component";
import checkboxComponent from "@/components/checkbox/checkboxComponent";
export default {
  components: {
    header1Component,
    checkboxComponent,
  },
  methods: {
    checkboxCondition26(params) {
      if (params === "checkbox1_26") {
        document.getElementById("checkbox1_26").checked = true;
        document.getElementById("checkbox2_26").checked = false;
        this.section26 = 1;
      } else if (params === "checkbox2_26") {
        document.getElementById("checkbox1_26").checked = false;
        document.getElementById("checkbox2_26").checked = true;
        this.section26 = 2;
      }
    },
    checkboxCondition(params) {
      if (params === "checkbox1") {
        document.getElementById("checkbox1").checked = true;
        document.getElementById("checkbox2").checked = false;
        document.getElementById("checkbox3").checked = false;
        this.status = "บุคคลทั่วไป";
      } else if (params === "checkbox2") {
        document.getElementById("checkbox1").checked = false;
        document.getElementById("checkbox2").checked = true;
        document.getElementById("checkbox3").checked = false;
        this.status = "หน่วยงานเอกชน";
      } else if (params === "checkbox3") {
        document.getElementById("checkbox1").checked = false;
        document.getElementById("checkbox2").checked = false;
        document.getElementById("checkbox3").checked = true;
        this.status = "หน่วยงานของราชการ";
      }
    },
    checkboxConditionAddress() {
      let checkboxAddress = document.getElementById("checkboxAddress");
      if (checkboxAddress.checked === true) {
        this.addressContact2.followAddress = true;
        this.addressContact2.placeNameContact =
          this.addressContact1.placeNameContact;
        this.addressContact2.addressName = this.addressContact1.addressName;
        this.addressContact2.subDistrict = this.addressContact1.subDistrict;
        this.addressContact2.district = this.addressContact1.district;
        this.addressContact2.province = this.addressContact1.province;
        this.addressContact2.zipCode = this.addressContact1.zipCode;
      } else if (checkboxAddress.checked === false) {
        this.addressContact2.followAddress = false;
        this.addressContact2 = {};
        this.addressContact2.followAddress = false;
      }
    },
    createRequest() {
      useRequest
        .createProject(
          this.section26,
          this.status,
          this.nameOfAttorney,
          this.projectNameTh,
          this.projectNameEn,
          this.projectDurationDay,
          this.addressContact1,
          this.addressContact2
        )
        .then((response) => {
          if (response.data.status === true) {
            console.log(response.data.msg);
          }
        });
    },
  },
  setup() {
    const section26 = ref(null);
    const status = ref(null);
    const nameOfAttorney = ref(null);
    const projectNameTh = ref(null);
    const projectNameEn = ref(null);
    const projectDurationDay = ref(null);
    const addressContact1 = ref({});
    const addressContact2 = ref({});

    const profile = JSON.parse(localStorage.getItem("profile"));
    const profiles = ref([
      {
        title: "ชื่อ - นามสกุล",
        content: `${profile.firstName} ${profile.lastName}`,
      },
      {
        title: "เลขประจำตัวประชาชน",
        content: `${profile.cardNumber}`,
      },
      {
        title: "ตำแหน่ง",
        content: `${profile.positionWorkName}`,
      },
      // {
      //     title: "ชื่อหน่วยงานหลัก",
      //     content: `${profile.cardNumber}`,
      // },
      // {
      //     title: "เลขทะเบียนนิติบุคคล",
      //     content: `${profile.cardNumber}`,
      // },
      {
        title: "อีเมล",
        content: `${profile.email}`,
      },
      {
        title: "โทรศัพท์",
        content: `${profile.phone}`,
      },
      {
        title: "โทรสาร",
        content: `${profile.fax}`,
      },
      {
        title: "โทรศัพท์มือถือ",
        content: `${profile.mobilePhone}`,
      },
    ]);
    return {
      profiles,
      section26,
      status,
      nameOfAttorney,
      projectNameTh,
      projectNameEn,
      projectDurationDay,
      addressContact1,
      addressContact2,
    };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.card1 {
  height: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  padding-left: 73px;
  padding-right: 73px;
  padding-top: 34px;
  margin-bottom: 36px;
}

.card2 {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 34px;
  padding-bottom: 36px;
  margin-bottom: 36px;
}

.conditionCard2 {
  margin-left: 73px;
  margin-right: 73px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  color: #242424;
  margin-bottom: 10px;
}

hr {
  margin-left: 33px;
  margin-right: 33px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c4c4c4;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #575757;
}

.contentActive {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
  width: 300px;
  margin-right: 24px;
  margin-left: 48px;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

textarea {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 105px;
  padding-left: 18px;
  padding-top: 10px;
}

button {
  width: 195px;
  height: 50px;
  color: white;
  background: #573c81;
  border-radius: 5px;
}
</style>